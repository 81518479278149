import { Injectable } from "@angular/core";
import { Observable, } from "rxjs";
import { Factory } from 'app/app.factory';
import { RecordStatus , PageType } from 'app/common/enum/type.enum';
import { DatePipe } from '@angular/common';

@Injectable()
export class MyTeamQueueService {
    constructor(private factory: Factory, private datepipe:DatePipe) {}
    public service: any=this.factory.getService();
    public teamSearchRecordData: string;
    public searchDataProperties: string;
    public searchActivityQ: string;
    public searchActivityProperties: string;
    public pageArgs: string;

    getPage(sort: string, order: string, page: number,pageSize: number,searchQ: string,searchProperties: string): Observable<PageResult> {
      this.pageArgs = this.bindPageArgs(sort,order,page,pageSize);
      return this.service.RecordList(searchQ,searchProperties + this.pageArgs + '&PageType=' + PageType.TeamQueue).then(res => {
        var result = new PageResult();
        var array= [];
        result.Total=res.TotalResults;
        if(result.Total>0){
          res.Results.forEach(element => {
            var temp=new RecordResult();
            var isCheckStatus = element.Fields.AgreementStatus.Value;
            var checkResubmitStatus = element.Fields.RecordStatus.Value;
            var LastRejected = element.Fields.LastRejected.Value;
            if(isCheckStatus == "Pending" && LastRejected){
              if (checkResubmitStatus.indexOf("Pending") != -1 || checkResubmitStatus.indexOf("Awaiting") != -1) {
                temp.isPendingRed = true;
              } else {
                temp.isPendingRed = false;
              }
            }
            if(this.checkUndefined(element.RecordNumber)){
              temp.number=element.RecordNumber.Value;
            }
            if(this.checkUndefined(element.Fields.AgreementName)){
              temp.typedTitle=element.Fields.AgreementName.Value;
            }

            if (this.checkUndefined(element.RecordAllHolds)) {
              temp.legalHold = element.RecordAllHolds.Value != '' ? true : false;
            }

            // if(this.checkUndefined(element.RecordAssignee)){
            //   temp.Assignee=element.RecordAssignee.NameString;
            // }
            // if(this.checkUndefined(element.RecordDateCreated)){
            //   temp.CreatedOn=element.RecordDateCreated.DateTime;
            // }
            if(this.checkUndefined(element.Fields.SubmitDates)){
              if(element.Fields.SubmitDates.DateTime.indexOf('0001-01-01T00')>-1){
                temp.SubmitDates=' ';
              }else{
                temp.SubmitDates=this.datepipe.transform( element.Fields.SubmitDates.DateTime, 'dd-MMM-yyyy');
                // temp.SubmitDates=' ';
              }


            }
            if(this.checkUndefined(element.Uri)){
              temp.Uri=element.Uri;
              // this.getActivity(element.Uri).then(function(data){
              //   temp.Assignee = data;
              // });
              //temp.Assignee=this.getActivity(element.Uri);
            }
            if(this.checkUndefined(element.Fields)){
              temp.AgreementType=this.bindValue(element.Fields.AgreementType);
              temp.SupplierID = this.bindValue(element.Fields.SupplierID);
              temp.PrimaryCountry=this.bindValue(element.Fields.PrimaryCountry);
              temp.PrimaryGU=this.bindValue(element.Fields.PrimaryGU);
              temp.PrimaryRegion=this.bindValue(element.Fields.PrimaryRegion);
              temp.KeyCategory=this.bindValue(element.Fields.KeyCategory);
              temp.CategoryVal=this.bindValue(element.Fields.CategoryVal);
              temp.RecordStatus=this.bindValue(element.Fields.RecordStatus);
              temp.AgreementStatus=this.bindValue(element.Fields.AgreementStatus);
              temp.SupplierName=this.bindValue(element.Fields.SupplierName);
              temp.ParentOrSupportingDocument=this.bindValue(element.Fields.ParentOrSupportingDocument);
              temp.Creator=this.bindValue(element.Fields.RecordSubmitter)
              temp.Assignee=this.bindValue(element.Fields.AssigneeVal);
              temp.MMDAgreementID=this.bindValue(element.Fields.MMDAgreementID);
              temp.AssigneeTo=this.bindValue(element.Fields.AssigneeVal).replace("ContractRegistrationTeam","Team Queue");
            }
            if(temp.RecordStatus == RecordStatus.AwaitingSAPID ||temp.RecordStatus == RecordStatus.PendingReview){
              temp.StatusChange = true;
            }else{
              temp.StatusChange = false;
           }
            array.push(temp);
          });

          // array.forEach(item=>{
          //   //item.Assignee=this.getActivity(item.Uri);
          //   this.getActivity(item.Uri).then(function(data){
          //     item.Assignee = data;
          //   });
          // });

          result.Data=array;
        }

        return result;
        });
    }

    getLocationMember(searchQ: string,searchProperties: string){
      var array= [];
      var temp=new LocationMember();
          temp.Uri='';
          temp.EID=0;
          temp.Key='ContractRegistrationTeam';
          temp.Value='Team Queue';
          array.push(temp);
      this.service.HPServiceLocation(searchQ,searchProperties).then(res => {
        if(res.TotalResults>0){
          res.Results.forEach(element => {
            var temp=new LocationMember();
            temp.Uri=element.Uri;
            temp.EID=element.LocationSurname.Value;
            temp.Key=element.LocationSurname.Value;
            temp.Value=element.LocationSurname.Value;
            array.push(temp);
          });
        }
      }, err => {
        console.log(err);
      });
      return array;
    }

    async getTotal(searchQ: string,searchProperties: string){
      var result= 0;
      await this.service.RecordListCount(searchQ,searchProperties).then(res => {
        result=res.TotalResults;
      }, err => {
        console.log(err);
      });
      return result;
    }

    // async getActivity(uri: string){
    //   var result="";
    //   this.searchActivityQ="format=json&q=wacName:Admin and wacWorkflow:[wkfInitiator:"+uri+"] ";
    //   this.searchActivityProperties = "properties=ActivityAssignedTo";
    //   await this.service.HPServiceActivity(this.searchActivityQ,this.searchActivityProperties).then(res => {
    //     if(res.TotalResults>0){
    //       res.Results.forEach(element => {
    //         if(this.checkUndefined(element.ActivityAssignedTo)){
    //           result = element.ActivityAssignedTo.NameString;
    //         }
    //       });
    //     }
    //   }, err => {
    //     console.log(err);
    //   });
    //   return result;
    // }

    bindPageArgs(sort: string, order: string,page: number,pageSize: number){
      var result="&pageSize="+ pageSize+"&start=" + ((page*pageSize)+1);
      //var result="&pageSize="+ pageSize+"&start=" + (((page+1)*pageSize)-pageSize);
      if(typeof(sort)!="undefined"){
        if(sort=="Creator"){
          sort="RecordSubmitter";
        }
        if(sort=="Assignee"){
          sort="AssigneeVal";
        }
        result+="&sortBy="+sort;
      }
      if(order=='desc'){
        result+="-";
      }
      if(typeof(sort)!="undefined"){
        if(sort=="SubmitDates"){
          result+=",SupplierName";
        }
      }
      return result;
    }

    bindValue(field:any){
      if(typeof(field)!='undefined'){
        return field.Value;
      }
      return "";
    }

    checkUndefined(field:any){
      if(typeof(field)!='undefined'){
       return true;
      }
      return false;
    }
}

export class LocationMember {
    public Uri: string;
    public EID: number;
    public Key: string;
    public Value: string;
}

export class RecordResult {
    number: string;
    legalHold: boolean;
    typedTitle: string;
    SupplierName: string;
    AgreementType: string;
    SupplierID: string;
    PrimaryCountry: string;
    PrimaryGU: string;
    PrimaryRegion: string;
    KeyCategory: string;
    CategoryVal: string;
    Creator: string;
    SubmitDates: string;
    Assignee: string;
    RecordStatus: string;
    Uri: number;
    StatusChange :boolean;
    ParentOrSupportingDocument: string;
    AgreementStatus:string;
    AssigneeTo:string;
    isPendingRed: boolean;
    MMDAgreementID: string;
  }

  export class PageResult {
    public Total: number;
    public Data: RecordResult[];
  }
